#root {
  height: 100vh;
}

.bottom-menu-100 {
  bottom: 100%;
}

.outline-none {
  outline: transparent;
}
